@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
}

@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,400;0,700;0,800;1,400;1,700;1,800&family=Source+Sans+Pro:ital,wght@0,400;0,700;1,400;1,700&display=swap");
* {
  font-weight: 400;
}

.font-subtitle {
  font-family: "Nunito Sans", sans-serif;
  font-size: 1.25rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
.font-h1,
.font-h2,
.font-h3,
.font-h4,
.font-h5,
.font-h6 {
  font-family: "Nunito Sans", sans-serif;
}

h6,
.font-h6 {
  font-size: 1.5rem;
}
h5,
.font-h5 {
  font-size: 2rem;
}
h4,
.font-h4 {
  font-size: 2.75rem;
  line-height: 3rem;
}
h3,
.font-h3 {
  font-size: 2.75rem;
}
h2,
.font-h2 {
  font-size: 4.6rem;
}
h1,
.font-h1 {
  font-size: 6rem;
}

.font-content-0,
.font-content-1,
.font-content-2,
.font-subcontent {
  font-family: "Source Sans Pro", sans-serif;
}
.font-content-0 {
  font-size: 1rem;
}
.font-content-1 {
  font-size: 0.9rem;
  line-height: 1.2rem;
}
.font-content-2 {
  font-size: 0.75rem;
}
.font-subcontent {
  font-size: 0.85rem;
}

/* Form */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

button:disabled {
  background: #9c9c9c !important;
}

/* Scrollbar */
.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 10px;
}

/* .custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
} */

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #9c9c9c;
  border-radius: 10px;
}

/* @media (max-width: 768px) {
  .font-subtitle {
    font-family: "Nunito Sans", sans-serif;
    font-size: 1.413rem;
  }

  h6,
  .font-h6 {
    font-size: 1.75rem;
  }
  h5,
  .font-h5 {
    font-size: 2.375rem;
  }
  h4,
  .font-h4 {
    font-size: 3.188rem;
    line-height: 3.5rem;
  }
  h3,
  .font-h3 {
    font-size: 4.188rem;
  }
  h2,
  .font-h2 {
    font-size: 5.625rem;
  }
  h1,
  .font-h1 {
    font-size: 7.5rem;
  }

  .font-content-0 {
    font-size: 1.225rem;
  }
  .font-content-1 {
    font-size: 1.1rem;
    line-height: 1.3rem;
  }
  .font-content-2 {
    font-size: 0.85rem;
  }
  .font-subcontent {
    font-size: 0.975rem;
  }
} */

@media (max-width: 540px) {
  .font-subtitle {
    font-family: "Nunito Sans", sans-serif;
    font-size: 1.213rem;
  }

  h6,
  .font-h6 {
    font-size: 1.35rem;
  }
  h5,
  .font-h5 {
    font-size: 1.975rem;
  }
  h4,
  .font-h4 {
    font-size: 2.788rem;
    line-height: 3.2rem;
  }
  h3,
  .font-h3 {
    font-size: 3.788rem;
  }
  h2,
  .font-h2 {
    font-size: 5.225rem;
  }
  h1,
  .font-h1 {
    font-size: 7.1rem;
  }

  .font-content-0 {
    font-size: 1.025rem;
  }
  .font-content-1 {
    font-size: 0.9rem;
    line-height: 1.1rem;
  }
  .font-content-2 {
    font-size: 0.65rem;
  }
  .font-subcontent {
    font-size: 0.775rem;
  }

  .container-soal table * {
    font-size: 3vw !important;
  }
}

.container-soal {
  width: 100%;
  word-wrap: break-word;
}

.container-soal img,
.container-soal figure {
  display: inline;
  max-width: 100%;
}

.container-soal p,
.ck-editor__editable p {
  margin-block-start: 1em;
  margin-block-end: 1em;
}

#tabel-ranking th,
.tabel-jd th,
#tabel-ranking td,
.tabel-jd td {
  text-align: center;
}

.agreement-container li {
  list-style: disc;
  list-style-position: outside;
}
.agreement-container ul {
  padding-left: 3rem;
  margin-bottom: 1rem;
}
